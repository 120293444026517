<template>
  <v-dialog @click:outside="$emit('closeModal')" v-model="show" max-width="400">
    <v-card>
      <v-card-title class="text-h5">
        <slot name="title"></slot>
      </v-card-title>

      <v-card-text>
        <slot name="text"></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="buttons"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmActionModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.showModal
      },
      set() {
        this.$emit('closeModal')
      },
    },
  },
}
</script>
